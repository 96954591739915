import React, { useState } from 'react'
import "../header/header.css"
import Ima from "../../assets/anmol.png"


const Header = () => {

    /*=============== Change Background Header ===============*/

    {window.addEventListener("scroll",function(){
        const header= document.querySelector(".header")

        // "When the scroll is higher that 200 viewport height, header background color will change"

        if (this.scrollY >= 80) header.classList.add("scroll-header")
        else header.classList.remove("scroll-header")
    })}

    /*=============== Toggle Menu ===============*/

    const[toggle,setToggle] = useState(false)
    const[activeNav,setActiveNav] = useState("#home")

    function handleClick(){
        setToggle(!toggle)
    }

  return (
    <header className="header">
    <nav className="nav container">
        <a href="index.html" className="nav_logo"><img src={Ima} alt="" /> </a>

        <div className={toggle?"nav_menu show-menu":"nav_menu"}>
            <ul className="nav_list grid">

                <li className="nav_item">
                    <a href="#home" onClick={() =>{
                       setActiveNav("#home") 
                    }} className={activeNav==="#home" ? "nav_link active-link" : "nav_link"}>
                        <i className="uil uil-estate nav_icon"></i>Home
                    </a>
                </li>

                <li className="nav_item">
                    <a href="#about" onClick={() =>{
                       setActiveNav("#about") 
                    }} className={activeNav==="#sbout" ? "nav_link active-link" : "nav_link"}>
                        <i className="uil uil-user nav_icon"></i>About
                    </a>
                </li>


                <li className="nav_item">
                    <a href="#skills" onClick={() =>{
                       setActiveNav("#skills") 
                    }} className={activeNav==="#skills" ? "nav_link active-link" : "nav_link"}>
                        <i className="uil uil-file-alt nav_icon"></i>Skills
                    </a>
                </li>

                <li className="nav_item">
                    <a href="#services" onClick={() =>{
                       setActiveNav("#services") 
                    }} className={activeNav==="#services" ? "nav_link active-link" : "nav_link"}>
                        <i className="uil uil-briefcase-alt nav_icon"></i>Services
                    </a>
                </li>

                <li className="nav_item">
                    <a href="#work" onClick={() =>{
                       setActiveNav("#portfolio") 
                    }} className={activeNav==="#portfolio" ? "nav_link active-link" : "nav_link"}>
                        <i className="uil uil-scenery nav_icon"></i>Portfolio
                    </a>
                </li>

                <li className="nav_item">
                    <a href="#contact" onClick={() =>{
                       setActiveNav("#contact") 
                    }} className={activeNav==="#contact" ? "nav_link active-link" : "nav_link"}>
                        <i className="uil uil-message nav_icon"></i>Contact
                    </a>
                </li>

            </ul>

            <i className="uil uil-times nav_close"  onClick={handleClick}></i>

        </div>

        <div className="nav_toggle" onClick={handleClick}>
        <i className="uil uil-apps"></i>
        </div>
    </nav>
</header>
  )
}

export default Header