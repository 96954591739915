import React from 'react';
import ReactDOM from 'react-dom';
import "../src/App.css"
import App from './components/App';




ReactDOM.render(
  <div>
    
    <App />
  </div>,
  document.getElementById('root')
)