import React from 'react'
import "../skills/skills.css"

const Backend = () => {
  return (
    <div className="skills_content">
        <h3 className="skills_title">Backend Developer</h3>


        <div className="skills_box">
            <div className="skills_group">

                <div className="skills_data">
                <i class='bx bxs-badge-check' ></i>

                <div>
                    <h3 className="skills_name">SQL</h3>
                    <span className="skills_level">Basic</span>
                </div>
                </div>

                <div className="skills_data">
                <i class='bx bxs-badge-check' ></i>

                <div>
                    <h3 className="skills_name">NodeJs</h3>
                    <span className="skills_level">Basic</span>
                </div>
                </div>

                <div className="skills_data">
                <i class='bx bxs-badge-check' ></i>

                <div>
                    <h3 className="skills_name">ExpressJs</h3>
                    <span className="skills_level">Basic</span>
                </div>
                </div>

                <div className="skills_data">
                <i class='bx bxs-badge-check' ></i>

                <div>
                    <h3 className="skills_name">MongoDB</h3>
                    <span className="skills_level">Basic</span>
                </div>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Backend