import React from 'react'
import Header from "../components/header/Header"
import "../App.css"
import Home from "../components/home/Home"
import About from './about/About'
import Skills from './skills/Skills'
import Services from './services/Services'
import Qualification from './qualification/Qualification'
import Contact from './contact/Contact'
import Footer from './footer/Footer'
import Scrollup from './scrollup/Scrollup'
import Work from './work/Work'

const App = () => {
  return (
   <>
   
   <Header />

   <main className="main">
    <Home />
    <About />
    <Skills />
    <Services />
    <Qualification />
    <Work />
    <Contact />
    
   </main>
   <Footer />
   <Scrollup />
   
   </>
  )
}

export default App