import React, { useState } from 'react'
import "../services/services.css"

const Services = () => {
    
const[toggleState,setToggleState] = useState(0)
   

 const toggleTab = (index) =>{
        setToggleState(index)
    }

const[toggle,setToggle] = useState(0)
   

 const toggleT = (index) =>{
        setToggle(index)
    }

const[toggleItem,setToggleItem] = useState(0)
   

 const toggleI = (index) =>{
        setToggleItem(index)
    }




  return (
    
    <section className="services section" id="services">
            <h2 className="section__title">Servicess</h2>
        <span className="section__subtitle">What I Offer</span>


        <div className="services_container container grid">
           
            <div className="services_content">
                <div>
                    <i className="bx bx-code-alt services_icon"></i>
                    <h3 className="services_title">Web <br /> Development</h3>
                </div>

                <span className="services_button" onClick={()=>
                    toggleTab(1)}>
                        View More <i className="uil uil-arrow-right services_button-icon"></i></span>


                <div  className={toggleState === 1 ? "services_modal active_modal" : "services_modal"}>
                    <div className="services_modal-content">
                        <i className="uil uil-times services_modal-close" onClick={()=>toggleTab(0)}></i>

                        <h3 className="services_modal-title">Web Developer</h3>
                        <p className="services_modal-description">Services
                        with more than 1 year of experience.
                        Providing quality work to clients and 
                        companies.</p>

                        <ul className="services_modal-services grid">
                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Building visually appealing and functional websites.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Designing intuitive and user-friendly interfaces.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Implementing responsive design for all devices.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Adapting to evolving web technologies
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Staying innovative with cutting-edge solutions.
                                    </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="services_content">
                <div>
                    <i className="bx bxl-sketch services_icon"></i>
                    <h3 className="services_title">Graphic <br /> Designing</h3>
                </div>

                <span className="services_button" onClick={()=>
        toggleT(1)}>View More <i className="uil uil-arrow-right services_button-icon"></i></span>


                <div className={toggle === 1 ? "services_modal active_modal" : "services_modal"}>
                    <div className="services_modal-content">
                        <i className="uil uil-times services_modal-close" onClick={()=>toggleT(0)}></i>

                        <h3 className="services_modal-title">Graphic Designer</h3>
                        <p className="services_modal-description">Service
                        with more than 1 year of experience.
                        Providing quality work to clients and 
                        companies.</p>

                        <ul className="services_modal-services grid">
                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Creating eye-catching and memorable graphic solutions.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Pushing creative boundaries to deliver innovative designs.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Balancing artistic expression with effective communication.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Balancing artistic expression with effective communication.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Collaborating with clients to bring their visions to life.
                                    </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="services_content">
                <div>
                    <i className="uil uil-edit services_icon"></i>
                    <h3 className="services_title">Technical <br /> Writing</h3>
                </div>

                <span className="services_button" onClick={()=>
        toggleI(1)}>View More <i className="uil uil-arrow-right services_button-icon"></i></span>


                <div className={toggleItem === 1 ? "services_modal active_modal" : "services_modal"}>
                    <div className="services_modal-content">
                        <i className="uil uil-times services_modal-close" onClick={()=>toggleI(0)}></i>

                        <h3 className="services_modal-title">Technical Writer</h3>
                        <p className="services_modal-description">Services
                        with more than 1 year of experience.
                        Providing quality work to clients and 
                        companies.</p>

                        <ul className="services_modal-services grid">
                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Crafting clear and concise technical documentation.
                                </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Simplifying complex concepts for easy understanding.

                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Translating technical jargon into accessible language.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Ensuring accuracy and consistency in technical content.
                                    </p>
                            </li>

                            <li className="services_modal-service">
                                <i className="uil uil-check-circle services_modal-icon"></i>
                                <p className="services_modal-info">
                                Delivering high-quality documentation for seamless user experience.
                                    </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            


        </div>
        
    </section>
   

  )
}

export default Services