import React,{useState} from 'react'
import "../qualification/qualification.css"

const Qualification = () => {


    const[toggleState,setToggleState] = useState(1)

    const toggleTab = (index) =>{
        setToggleState(index)
    }

  return (
    <section className="qualification section" id="qualification">
        <h2 className="section__title">Qualification</h2>
        <span className="section__subtitle">My Educational Journey</span>

        <div className="qualification_container container">
            <div className="qualification_tabs">
                <div className={
                    toggleState===1 
                    ? "qualification_button qualification_active button--flex" 
                    :"qualification_button button--flex"}

                    onClick={() =>toggleTab(1)}
                    >
                    
                    <i className="uil uil-graduation-cap 
                    qualification_icon"></i> Education
                </div>

                <div className={
                    toggleState===2 
                    ? "qualification_button qualification_active button--flex" 
                    :"qualification_button button--flex"}
                    onClick={() =>toggleTab(2)}
                    >
                    <i className="uil uil-briefcase-alt 
                    qualification_icon"></i>Experience
                </div>
            </div>

            <div className="qualification_sections">

                {/* -----Education-----  */}

                <div className={
                    toggleState===1 
                    ? "qualification_content qualification_content-active" 
                    :"qualification_content "}>


                    <div className="qualification_data">
                        <div>
                            <h3 className="qualification_title">High School (10th)</h3>
                            <span className="qualification_subtitle">
                                Oakland Public School
                            </span>
                            <div className="qualification_calendar">
                                <i className="uil uil-calendar-alt calendar_icon">
                              </i>2015 - 2016
                            </div>
                        </div>

                        <div>
                            <span className="qualification_rounder"></span>
                            <span className="qualification_line"></span>
                        </div>
                    </div>



                    <div className="qualification_data">
                        <div></div>

                        <div>
                            <span className="qualification_rounder"></span>
                            <span className="qualification_line"></span>
                        </div>
                        <div>
                            <h3 className="qualification_title">Senior Secondary School (12th)</h3>
                            <span className="qualification_subtitle">
                                Oakland Public School
                            </span>
                            <div className="qualification_calendar">
                                <i className="uil uil-calendar-alt calendar_icon">
                              </i>2017 - 2018
                            </div>
                        </div>

                        
                    </div>


                    <div className="qualification_data">
                        
                        <div>
                            <h3 className="qualification_title">BTech, Computer Schience & Engineering</h3>
                            <span className="qualification_subtitle">
                                Bennett University
                            </span>
                            <div className="qualification_calendar">
                                <i className="uil uil-calendar-alt calendar_icon">
                              </i>2019 - Present
                            </div>
                        </div>

                        <div>
                            <span className="qualification_rounder"></span>
                            <span className="qualification_line"></span>
                        </div>
                    </div>


                    {/* <div className="qualification_data">
                        <div></div>

                        <div>
                            <span className="qualification_rounder"></span>
                            <span className="qualification_line"></span>
                        </div>
                        <div>
                            <h3 className="qualification_title">UX Expert</h3>
                            <span className="qualification_subtitle">
                                Bennett University
                            </span>
                            <div className="qualification_calendar">
                                <i className="uil uil-calendar-alt">
                              </i>2019 - 2021
                            </div>
                        </div>

                        
                    </div> */}
                </div>


                {/* -----Experience-----  */}

                <div className={
                    toggleState===2 
                    ? "qualification_content qualification_content-active" 
                    :"qualification_content "}>


                    <div className="qualification_data">
                        <div>
                            <h3 className="qualification_title">Software Engineer</h3>
                            <span className="qualification_subtitle">
                                Celebal Technologies
                            </span>
                            <div className="qualification_calendar">
                                <i className="uil uil-calendar-alt calendar_icon">
                              </i>3 months
                            </div>
                        </div>

                        <div>
                            <span className="qualification_rounder"></span>
                            <span className="qualification_line"></span>
                        </div>
                    </div>



                    <div className="qualification_data">
                        <div></div>

                        <div>
                            <span className="qualification_rounder"></span>
                            <span className="qualification_line"></span>
                        </div>
                        <div>
                            <h3 className="qualification_title">Teaching Assistant</h3>
                            <span className="qualification_subtitle">
                                Bennett University
                            </span>
                            <div className="qualification_calendar">
                                <i className="uil uil-calendar-alt calendar_icon">
                              </i>5 months
                            </div>
                        </div>

                        
                    </div>


                    {/* <div className="qualification_data">
                       
                        
                        <div>
                            <h3 className="qualification_title">Web Designer</h3>
                            <span className="qualification_subtitle">
                                Bennett University
                            </span>
                            <div className="qualification_calendar">
                                <i className="uil uil-calendar-alt calendar_icon">
                              </i>2019 - Present
                            </div>
                        </div>

                        <div>
                            <span className="qualification_rounder"></span>
                            <span className="qualification_line"></span>
                        </div>
                    </div> */}


                </div>



            </div>
        </div>

        
    </section>
  )
}

export default Qualification