import React from 'react'
import "../footer/footer.css"
import Ima from "../../assets/anmol.png"

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer_container container">
            <h1 className="footer_title footer_logo"><img src={Ima} alt="" /></h1>

        <ul className="footer_list">
            <li>
                <a href="#about" 
                className="footer_link">About</a>
            </li>

            <li>
                <a href="#work" 
                className="footer_link">Projects</a>
            </li>

            <li>
                <a href="#services" 
                className="footer_link">Services</a>
            </li>

        </ul>

        <div className="footer_social">

        <a href="" 
        className="footer_social-link" target='_blank'>
        <i class="uil uil-instagram"></i>
        </a>


        <a href="" 
        className="footer_social-link" target='_blank'>
            <i class="uil uil-facebook-f"></i>
        </a>


        <a href="https://github.com/e19cse213" 
        className="footer_social-link" target='_blank'>
        <i class="uil uil-github-alt"></i>
        </a>


        </div>

        <span className="footer_copy">
            &#169;Copyright @AnmolKaloni
        </span>

        </div>
    </footer>
  )
}

export default Footer